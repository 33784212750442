import React from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col } from '../../../components/ui/wrapper'
import SectionTitle from '../../../components/ui/section-title'
import BoxLargeImage from '../../../components/box-large-image/layout-one-ru'
import { SectionWrap } from './section.style'

import image1 from '../../../data/images/bg/machines/smt/smt-printing.png'
import image2 from '../../../data/images/bg/machines/smt/smt-pp.png'
import image3 from '../../../data/images/bg/machines/smt/smt-reflow.png'
import image4 from '../../../data/images/bg/machines/smt/smt-spi.png'
import image5 from '../../../data/images/bg/machines/smt/smt-aoi.png'
import image6 from '../../../data/images/bg/machines/smt/smt-conveyer.png'
import image7 from '../../../data/images/bg/machines/cleaning/pcb-cleaning.png'
import image8 from '../../../data/images/bg/machines/cleaning/stencil-cleaning.png'
import image9 from '../../../data/images/bg/machines/cleaning/pallete-cleaning.png'
import image10 from '../../../data/images/bg/machines/tht/insertion.png'
import image11 from '../../../data/images/bg/machines/tht/selective.png'
import image12 from '../../../data/images/bg/machines/tht/wave.png'
import image13 from '../../../data/images/bg/machines/coating/selective-coating.png'
import image14 from '../../../data/images/bg/machines/coating/dip-coating.png'
import image15 from '../../../data/images/bg/machines/coating/spray-coating.png'
import image16 from '../../../data/images/bg/machines/wire/cutting.png'
import image17 from '../../../data/images/bg/machines/wire/striping.png'
import image18 from '../../../data/images/bg/machines/wire/crimping.png'
import image19 from '../../../data/images/bg/machines/cutting/blade-cutting.jpg'
import image20 from '../../../data/images/bg/machines/cutting/router-cutting.jpg'
import image21 from '../../../data/images/bg/machines/cutting/laser-cutting.jpg'
import image22 from '../../../data/images/bg/machines/storage/stock-storage.jpg'
import image23 from '../../../data/images/bg/machines/storage/components-storage.jpg'
import image24 from '../../../data/images/bg/machines/storage/paste-storage.jpg'
import image25 from '../../../data/images/bg/machines/filtration/filtration-small.jpg'
import image26 from '../../../data/images/bg/machines/filtration/filtration-big.jpg'
import image27 from '../../../data/images/bg/machines/filtration/filtration-laser.jpg'
import image28 from '../../../data/images/bg/machines/dispensing/dispensing-automatic.jpg'
import image29 from '../../../data/images/bg/machines/dispensing/dispensing-semi-automatic.jpg'
import image30 from '../../../data/images/bg/machines/dispensing/dispensing-manual.jpg'
import image31 from '../../../data/images/bg/machines/coating/curing.jpg'
import image32 from '../../../data/images/bg/machines/coating/curing-con.jpg'
import image33 from '../../../data/images/bg/lines/janets-300x300.jpg'
import image34 from '../../../data/images/bg/lines/tracemonitor-300x300.jpg'
import image35 from '../../../data/images/bg/lines/ifs-nx-300x300.jpg'
import image36 from '../../../data/images/bg/machines/coating/coating-aoi.jpg'

const BoxSection = ({ sectionTitleStyle, machine, linkStyle, sectionBottomStyle }) => {
    let boxContents = [];
    let description = "";
    let subtitle = "";
    let title = "";
    if (machine === "smt") {
    boxContents = [
        {
            id: 1,
            imageSrc: image1,
            title: 'Принтеры паяльной пасты',
            desc: 'Оборудование для нанесения паяльной пасты через металический трафарет на контактные площадки печатных плат.',
            path: '/оборудование-для-монтажа/поверхностный-монтаж/принтеры-паяльной-пасты'
        },
        {
            id: 2,
            imageSrc: image2,
            title: 'Установщики компонентов',
            desc: 'Оборудование для автоматической установки электронных компонентов SMD на печатные платы. Установщики для работы в линии.',
            path: '/оборудование-для-монтажа/поверхностный-монтаж/автоматы-установки-компонентов'
        },
        {
            id: 3,
            imageSrc: image3,
            title: 'Печи оплавления паяльной пасты',
            desc: 'Паяльные печи для конвекционного плавления паяльной пасты. Отдельно-стоящее оборудование и конвейерные печи.',
            path: '/оборудование-для-монтажа/поверхностный-монтаж/печи-паяльные'
        },
        {
            id: 4,
            imageSrc: image4,
            title: 'Инспекция нанесения пасты - SPI',
            desc: 'Автоматическая оптическая инспекция нанесения паяльной пасты на контактные площадки печатных плат.  Машины для 3D измерения.',
            path: '/оборудование-для-монтажа/поверхностный-монтаж/инспекция-паяльной-пасты'
        },
        {
            id: 5,
            imageSrc: image5,
            title: 'Инспекция после пайки - AOI',
            desc: 'Автоматическая оптическая инспекция качества монтажа электронных компонентов после оплавления паяльной пасты. 3D AOI.',
            path: '/оборудование-для-монтажа/поверхностный-монтаж/автоматическая-оптическая-инспекция'
        },
        {
            id: 6,
            imageSrc: image6,
            title: 'Перемещение печатных плат',
            desc: 'Конвейерные сборки для перемещения плат в производственной линии. Устройства загрузки и выгрузки плат. Конвейеры и буферы.',
            path: '/оборудование-для-монтажа/поверхностный-монтаж/перемещение-печатных-плат'
        }
    ];
     description = "Поверхностный монтаж или технология  поверхностного монтажа (SMT) – это совокупность технологических процессов монтажа электронных компонентов  SMD на поверхность печатных плат. Технология включает в себя три основные процессы. Нанесение паяльной пасты на контактные площадки печатных плат через металлический трафарет или с помощью дозатора. Установка электронных компонентов используя специальные автоматические машины с вакуумным захватом электронных компонентов. Оплавление паяльной пасты в специальной паяльной печи."; 
     subtitle = "Оборудование для SMT";
     title = "Автоматизация сборки плат<br><span>Поверхностный монтаж</span>";
} else if (machine === "cleaning") {
     boxContents = [
        {
            id: 1,
            imageSrc: image7,
            title: 'Оборудование для мойки плат',
            desc: 'Оборудование для автоматической мойки плат, электронных сборок. Машины для струйной и ультразвуковой очистки печатных плат.',
            path: '/оборудование-для-монтажа/оборудование-для-мойки/отмывка-печатных-плат'
        },
        {
            id: 2,
            imageSrc: image8,
            title: 'Оборудование для мойки трафаретов',
            desc: 'Оборудование для автоматической мойки трафаретов для нанесения паяльной пасты. Струйные полностью автоматические машины.',
            path: '/оборудование-для-монтажа/оборудование-для-мойки/отмывка-трафаретов'
        },
        {
            id: 3,
            imageSrc: image9,
            title: 'Оборудование для мойки оснастки',
            desc: 'Оборудование для мойки технологической оснастки: паллеты для волны, паллет для нанесения покрытий и частей машин.',
            path: '/оборудование-для-монтажа/оборудование-для-мойки/мойка-паллет-кареток'
        }
    ];
     description = "Оборудование для отмывки печатных плат и трафаретов применяют для отмывки после пайки остатков флюсов и других загрязнений с печатных плат и остатков паяльной пасты с апертур трафаретов. Как правило, оборудование для отмывки печатных плат используется для изделий требующих повышенной надежности в эксплуатации и работающих в неблагоприятных климатических условиях: автомобильная электроника, системы жизнеобеспечения, военная техника, медицинская техника, промышленная автоматика и д.р. По принципу работы оборудование для отмывки печатных плат делится на ультразвуковое и струйное.";
     subtitle = "Оборудование для мойки плат и трафаретов";
     title = "Автоматизация процессов очистки<br><span>Платы, трафареты, паллеты</span>";
} else if (machine === "tht") {
    boxContents = [
        {
            id: 1,
            imageSrc: image10,
            title: 'Установщики в отверстия плат',
            desc: 'Оборудование для автоматической установки электронных компонентов в отверстия печатных плат. Обрезка и гибка выводов при установке.',
            path: '/оборудование-для-монтажа/монтаж-в-отверстия/установщик-компонентов-в-отверстия'
        },
        {
            id: 2,
            imageSrc: image11,
            title: 'Машины селективной пайки',
            desc: 'Оборудование для селективной пайки электронных компонентов монтируемых в отверстия печатных плат.',
            path: '/оборудование-для-монтажа/монтаж-в-отверстия/селективная-пайка'
        },
        {
            id: 3,
            imageSrc: image12,
            title: 'Машины пайки волной припоя',
            desc: 'Оборудование для пайки электронных компонентов монтируемых в отверстия печатных плат волной расплавленного припоя.',
            path: '/оборудование-для-монтажа/монтаж-в-отверстия/пайка-волной-припоя'
        }
    ];
    description = "Монтаж электронных компонентов в отверстия печатных плат  (THT) - это классическая технология монтажа электронных компонентов, которая активно применятся до сегодняшнего времени. Монтаж в отверстия включает в себя два основных технологических процесса. Установка электронных компонентов в отверстия печатных плат. Для автоматизации процессов установки компонентов применяются специальные автоматические установщики в отверстия. Групповая пайка на машине пайки волной припоя или селективная пайка на автоматической машине для селективной пайки.";
     subtitle = "Оборудование для монтажа в отверстия";
     title = "Автоматизация сборки плат<br><span>Выводной монтаж</span>";
} else if (machine === "coating") {
    boxContents = [
        {
            id: 1,
            imageSrc: image13,
            title: 'Селективное нанесение',
            desc: 'Оборудование для селективного нанесения влагозащитных покрытий на печатные платы. Машины для работы в линии и настольные',
            path: '/оборудование-для-монтажа/влагозащита-электроники/селективная-влагозащита'
        },
        {
            id: 2,
            imageSrc: image14,
            title: 'Нанесение погружением',
            desc: 'Оборудование для нанесения защитных покрытий методом погружения. Безопасное исполнение. Машины с пневматическим приводом.',
            path: '/оборудование-для-монтажа/влагозащита-электроники/влагозащита-погружением'
        },
        {
            id: 3,
            imageSrc: image15,
            title: 'Ручное нанесения покрытий',
            desc: 'Бюджетные решения для нанесения влагозащитных покрытий на электронные блоки. Системы для ручного нанесения распылением.',
            path: '/оборудование-для-монтажа/влагозащита-электроники/нанесение-защитных-покрытий'
        },
        {
            id: 4,
            imageSrc: image31,
            title: 'Отверждения покрытий',
            desc: 'Оборудование для отверждения защитных покрытий в УФ и ИК печах конвейерного типа. Машины для работы в составе линии',
            path: '/оборудование-для-монтажа/влагозащита-электроники/оборудование-для-отверждения'
        },
        {
            id: 5,
            imageSrc: image32,
            title: 'Конвейры для линии',
            desc: 'Оборудование для перемещения плат в линии для нанесения защитных покрытий. Конвейеры имеют специальное исполнение.',
            path: '/оборудование-для-монтажа/влагозащита-электроники/конвейеры-защитных-покрытий'
        },
        {
            id: 6,
            imageSrc: image36,
            title: 'Оптическая инспекция',
            desc: 'Оборудование для автоматической оптической инспекции нанесения защитных покрытий на платы',
            path: '/оборудование-для-монтажа/влагозащита-электроники/инспекция-нанесения-защитных-покрытий'
        }
    ];
    description = "Существует множество изделий электроники, которые эксплуатируются в различных неблагоприятных условия. Это не обязательно тропические страны. Под капотом нашего автомобиля становится все больше электроники, а условия работы сходны с полетом в космос. Не зависимо от условия изделия должно работать надежно и долго. Для защиты электронных сборок применяют различные покрытия в зависимости от требований к эксплуатации изделия. Платы могут покрываться целиком или частично. Могут покрываться отдельные чувствительные компоненты. Основной рынок применения влагозащитных покрытий это: автомобильная электроника, оборонная промышленность, авиастроение, LED дисплеи и освещение, альтернативные источники электроэнергии.";
     subtitle = "Оборудование для нанесения защитных покрытий";
     title = "Автоматизация процессов нанесения<br><span>Влагозащита электроники</span>";
} else if (machine === "wire") {
    boxContents = [
        {
            id: 1,
            imageSrc: image16,
            title: 'Резка и зачистка провода и кабеля',
            desc: 'Оборудование для мерной резки и зачистки проводом и кабелей. Резка, снятие верхнего покрытия кабелей и покрытия внутренних проводов за один проход.',
            path: '/оборудование-для-монтажа/обработка-провода/оборудование-резки-зачистки-проводов'
        },
        {
            id: 2,
            imageSrc: image17,
            title: 'Зачистка провода и кабеля',
            desc: 'Оборудование для зачистки проводов. Зачистка внешнего покрытия кабелей и покрытия внутренних проводов. Зачистка коаксиальных и силовых кабелей.',
            path: '/оборудование-для-монтажа/обработка-провода/машины-зачистки-проводов'
        },
        {
            id: 3,
            imageSrc: image18, 
            title: 'Опрессовка контактов',
            desc: 'Оборудование для обжимки терминалов. Автоматическое и полу-автоматическое оборудование для кремповки контактов. Опрессовка контактов в россыпи.',
            path: '/оборудование-для-монтажа/обработка-провода/оборудование-опрессовки-контактов'
        }
    ];
    description = "Производство электроники пока не смогло уйти от проводного монтажа, который трудно автоматизировать. Особенно много времени занимают технологические процессы подготовки проводов и кабелей: резка провода, зачистка изоляции, запрессовка контактов, лужение выводов. Эти операции не требую высокой квалификации, но занимают существенно время и влияют на себестоимость изделия. Применение простых недорогих устройств снижает время подготовки проводов и улучшаю качество заготовок.  Оборудование окупает себя за несколько месяцев и не требует специального обслуживания и условий эксплуатации.";
     subtitle = "Оборудование для обработки провода";
     title = "Автоматизация процессов резки и зачистки<br><span>Обработка проводов и кабелей</span>";
}else if (machine === "cutting") {
    boxContents = [
        {
            id: 1,
            imageSrc: image19,
            title: 'Настольные установки для разделения групповых панелей',
            desc: 'Настольное оборудование для разделения предварительно скрайбованих групповых панелей печатных плат. Для работы вне линии',
            path: '/оборудование-для-монтажа/разделение-групповых-заготовок/устройства-разделения-печатных-плат'
        },
        {
            id: 2,
            imageSrc: image20,
            title: 'Фрезерное оборудование для разделения печатных плат',
            desc: 'Автоматическое и полу-автоматическое оборудование для разделения групповых заготовок печатных плат. Машины для работы в линии и отдельно.',
            path: '/оборудование-для-монтажа/разделение-групповых-заготовок/оборудование-разделения-печатных-плат'
        },
        {
            id: 3,
            imageSrc: image21,
            title: 'Системы разделения групповых заготовок лазером',
            desc: 'Автоматическое оборудование для разделения печатных плат сложной формы, гибких печатных плат и других сложных задач.',
            path: '/оборудование-для-монтажа/разделение-групповых-заготовок/разделение-групповых-заготовок-лазером'
        }
    ];
    description = "Для обеспечения максимальной производительности оборудования для монтажа применяют групповые заготовки (мультиплицированные платы). Сами платы в групповой заготовке могут разграничиваться различными способами: скрайбирование, перемычки, перфорация или другим способом, который не уменьшает жесткости платы. Применение групповых заготовок требует дополнительного технологического процесса и создания рабочего места. Разделение групповых заготовок печатных плат – это серьезный и ответственный процесс. Правильный подбор оборудование позволяет снизить уровень брака и уменьшить в целом расходы на производство изделия.";
     subtitle = "Оборудование для разделения групповых заготовок";
     title = "Автоматизация процессов разделения<br><span>Увеличение производительности</span>";
}else if (machine === "storage") {
    boxContents = [
        {
            id: 1,
            imageSrc: image22,
            title: 'Автоматизированные склады компонентов',
            desc: 'Автоматизированные системы для управления комплектацией электронных компонентов и другими частями',
            path: '/оборудование-для-монтажа/автоматизированные-системы-хранения/автоматизированные-склады-компонентов'
        },
        {
            id: 2,
            imageSrc: image23,
            title: 'Шкафы сухого хранения',
            desc: 'Оборудование для сухого хранения и сушки электронных компонентов и печатных плат',
            path: '/оборудование-для-монтажа/автоматизированные-системы-хранения/шкафы-сухого-хранения'
        },
        {
            id: 3,
            imageSrc: image24,
            title: 'Хранение паяльной пасты',
            desc: 'Оборудование для хранения паяльной пасты с поддержанием необходимой температуры и влажности',
            path: '/оборудование-для-монтажа/автоматизированные-системы-хранения/хранение-паяльной-пасты'
        }
    ];
    description = "Интеллектуальное управление складом электронных компонентов становится все более важным фактором успеха в производственных процессах сборки электроники. Управление хранением обеспечивает оптимальную производительность выпуска готовых изделий. Автоматизированные склады электронных компонентов обеспечивают высокую гибкость и эффективность при комплектовании изделий и быстрый переход на выпуск нового продукта, минимизируя затраты времени и человеческий фактор. Эффективная система управления хранением экономит время и деньги в течение всего производственного процесса. Оборудование  включает в себя широкий спектр различных  решений для автоматизации комплектования и хранения электронных компонентов. Оборудование имеет модульную конструкцию и возможность наращивания. Интеллектуальная интеграция с существующими системами хранения.";
    subtitle="Автоматизированные системы хранения компонентов и материалов"
    title="Автоматизация управлением комплектацией<br><span>Увеличение эффективности производства</span>";
}else if (machine === "filtration") {
    boxContents = [
        {
            id: 1,
            imageSrc: image25,
            title: 'Бюджетные автономные вытяжки с фильтрацией',
            desc: 'Локальные вытяжные системы с фильтрацией воздуха для оснащения до 1-3 рабочих мест для ручного монтажа',
            path: '/оборудование-для-монтажа/дымоулавливающие-системы/дымоуловители-для-пайки'
        },
        {
            id: 2,
            imageSrc: image26,
            title: 'Многоместные дымоулавливающие системы',
            desc: 'Многоместные дымоулавливающие системы для оснащения производственного участка ручного монтажа',
            path: '/оборудование-для-монтажа/дымоулавливающие-системы/вытяжки-для-пайки'
        },
        {
            id: 3,
            imageSrc: image27,
            title: 'Дымоулавливающие системы для лазерных станков',
            desc: 'Интеллектуальные очистные системы для улавливания вредных веществ при лазерной обработке',
            path: '/оборудование-для-монтажа/дымоулавливающие-системы/вытяжки-для-лазерных-станков'
        }
    ];
    description = "Для предприятий занимающихся производством прототипов, предприятий мелкосерийного производства и мастерских не всегда есть возможность инвестировать в средства в дорогостоящую вытяжную вентиляцию.  Автономные фильтрующие системы  позволяют  решить задачу отвода вредных веществ, что возникают во время пайки и обеспечить охрану здоровья персонала. Автономные вытяжные системы задерживаю механические частицы и вредные газы, обеспечивая механическую и химическую очистку воздуха в рабочей зоне монтажников примененяя комбинированные фильтры с трехступенчатой системой фильтрации.";
    subtitle = "Автономные дымоулавливающие системы"
    title = "Безопастное производство<br><span>Охрана труда</span>";
}else if (machine === "dispensing") {
    boxContents = [
        {
            id: 1,
            imageSrc: image28,
            title: 'Автоматические дозаторы',
            desc: 'Автоматические дозаторы для нанесения паяльной пасты и клея, других материалов. Для работы в линии и отдельно.',
            path: '/оборудование-для-монтажа/дозаторы-паяльной-пасты/автоматический-дозатор'
        },
        {
            id: 2,
            imageSrc: image29,
            title: 'Полу автоматические дозаторы',
            desc: 'Полу автоматические дозаторы для нанесения паяльной пасты и клея на печатные платы. Настольное исполнение',
            path: '/оборудование-для-монтажа/дозаторы-паяльной-пасты/автомат-дозирования'
        },
        {
            id: 3,
            imageSrc: image30,
            title: 'Ручные дозаторы пасты и клея',
            desc: 'Ручные устройства для нанесения паяльной пасты и клея на контактные площадки печатных плат. Простая эксплуатация',
            path: '/оборудование-для-монтажа/дозаторы-паяльной-пасты/ручные-дозаторы'
        }
    ];
    description = "Дозаторы паяльной пасты и клея применяются, как оборудование для поверхностного монтажа SMT. В условиях мелкосерийного многономенклатурного производства монтажа печатных плат используют дозаторы паяльной пасты, как  отдельностоящую машину для экономии средств и времени на изготовление трафаретов. Дозаторы клея применяют в условиях серийного и крупносерийного производств для технологии смешанного монтажа для фиксации SMD во время пайки волной припоя, как отдельностоящую машину, так и для работы в производственной линии. Дозаторы паяльной пасты и клея на порядок повышаю гибкость производства и рекомендуются для контрактных производителей электроники. Применение автоматических дозаторов не ограничивается только  SMT. Машини используют для процессов герметизации механических частей, заливки, нанесения термопаст и др.";
    subtitle = "Нанесение материалов"
    title = "Автоматическое дозирование<br><span>Автоматизация процессов</span>";
}else if (machine === "software") {
    boxContents = [
        {
            id: 1,
            imageSrc: image33,
            title: 'Программные решения для SMT',
            desc: 'Подготовка и оптимизация программ для установки компонентов. Управление производственными линиями. Визуализация производственных показателей',
            path: '/комплексные-решения/программное-обеспечение/janets'
        },
        {
            id: 2,
            imageSrc: image34,
            title: 'Программное обеспечение для анализа',
            desc: 'Выявление и устранение производственных отклонений. Ранний анализ данных по надежности процессов. Визуализация состояния линии',
            path: '/комплексные-решения/программное-обеспечение/trace-monitor'
        },
        {
            id: 3,
            imageSrc: image35,
            title: 'Система подготовки питателей',
            desc: 'Отслеживание компонентов (traceability). Программная интеграция всех систем участвующих в подготовке (склад, тележки с питателями, отдельные питатели)',
            path: '/комплексные-решения/программное-обеспечение/ifs-nx'
        }
    ];
    description = "Комплексные программные решения для обеспечения всего цикла производства электронных изделий – от поступления комплектации и материалов до отгрузки готовой продукции заказчику. Автоматический учет и хранение электронных компонентов, подготовка программ и управление производственными линиями, полное отслеживание комплектации и материалов, управление качеством и много других полезных функций.";
    subtitle="Программные решения"
    title="Автоматизация производства<br><span>Поддержка Industry 4.0</span>";
}

    return (
        <SectionWrap>
            <Container>
                <Row>
                    <Col xl={12}>
                        <SectionTitle
                            {...sectionTitleStyle}
                            subtitle={subtitle}
                            title={title}
                        />
                    </Col>
                </Row>
                <h6 style={{textAlign: "justify"}}>{description}</h6>
                <Row className="gutter-xl-70">
                    {
                        boxContents.map(boxContent => {
                            return (
                                <Col lg={4} md={6} className="box-item" key={`box-image-${boxContent.id}`}>
                                    <BoxLargeImage 
                                        title={boxContent.title}
                                        desc={boxContent.desc}
                                        imageSrc={boxContent.imageSrc}
                                        path={boxContent.path}
                                    />
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>
        </SectionWrap>
    )
}


BoxSection.propTypes = {
    sectionTitleStyle: PropTypes.object,
    linkStyle: PropTypes.object,
    sectionBottomStyle: PropTypes.object
}

BoxSection.defaultProps = {
    sectionTitleStyle: {
        mb: '30px',
        responsive: {
            medium: {
                mb: '17px'
            }
        }
    },
    linkStyle: {
        fontSize: '18px',
        fontWeight: 500,
        lineheight: 1.40,
        color: 'primary',
        layout: 'underline',
        hover: {
            layout: 2
        }
    },
    sectionBottomStyle: {
        mt: '40px',
        align: 'center'
    }
}

export default BoxSection;